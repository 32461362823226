.footerClass{
    text-decoration: none;
    background: #f5f5f5;
  text-align: center;
    padding: 40px 5% 0 5% #f5f5f5;
    position: relative;
     box-shadow: inset 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
     margin-bottom: 0;
}
.transition{
    text-decoration: none;  
     color: #757575;
    font-size: 16px;
     font-size: 1rem;
     margin-bottom: 0;
     height: 60px;;
     margin-top: 10px;
}

.gradient-white {
    width: 10px;
}

.backToTopIcon{
    position: absolute;
    right: 5%;
    top: 10%;
    width: 55px;
    height: 55px;
    cursor: pointer;
    color: #262626;
    z-index: 5;
}

.backToTopIcon:hover{
color: coral;
}

.mailIcon{
    position: absolute;
    left: 5%;
    top: 10%;
    width: 55px;
    height: 55px;
    cursor: pointer;
    color: #262626;
    z-index: 5;
}

.mailIcon:hover{
color: coral;
}

.emailNote{
    display: none;
}

@media (max-width: 600px) {

    .footerClass{
        text-align: left;
    }
    .transition{
        margin-left: 5%;
        font-size: 1px;
        font-size: .7rem;
    }
    .backToTopIcon{
        width: 45px;
        height: 45px;
    }
    .mailIcon{
        display: none;
    }
    .emailNote{
        display: inline;
        text-decoration: none;
    }
  
  }