.aboutClass {
    /* position: relative;  */
    top: 0;
    left: 0;
}

.bgImg {
    max-width: 100%;
    margin-top: 5%;
}

.aboutContainer {
    max-width: 1000px;
    border-bottom: #dddddd solid 1px;
}

.contentLeft {
    opacity: 1;
    position: absolute;
    right: 75%;
    left: 5%;
    top: 15%;
    color: #333333;
    font-weight: normal;
    text-decoration: none;
}


.contentRight {
    opacity: 1;
    position: absolute;
    right: 5%;
    left: 75%;
    top: 15%;
    color: #333333;
    font-weight: normal;
    text-decoration: none;
}

.description h5 {
    font-size: large;
    color: gray;
    padding-left: 5px;
}
.mobileDownloadClass{
    display: none;
    text-decoration: none;
    text-align: center;
    line-height: 10px;
margin-top: 15%;
    background-color: black;
    font-size: 2px;
    padding-top: 10%;
    padding-bottom: 0%;
    box-shadow: 1px 1px 10px 1 px black;
    transition: transform .4s;
    border-radius: 10px;
}
.mobileDownloadClass a{
    text-decoration: none;
    color: white;
    font-size: 10px;
    
}

@media (max-width: 800px) {

    .description h1 {
        font-size: x-large;
    }

    .description p {
        display: none;
    }

    .countClass h1{
        font-size: large    ;
    }
    .countClass h5{
        font-size: medium   ;
    }
    .contentRight{
        right: 5%;
        left: 75%;
        top: 20%;
    }

}

@media (max-width: 600px) {
  .whatsNew {
    display: none;
  }

    .contentLeft .description h1 {
        font-size: medium;
    }

    .contentRight .description h1 {
        font-size: medium;
    }

    .contentLeft .description .p .a .span {
        font-size: small;
    }

    .downloadClass {
        display: none;
    }
    .mobileDownloadClass{
        display: flex;
    }
    .countClass h1{
        font-size: medium    ;
    }
    .countClass h5{
        font-size: x-small   ;
    }
    .contentRight{
        right: 5%;
        left: 75%;
        top: 17%;
    }
}



.countClass {
    color: white;
    /* border:5px solid black; */
    background-color: black;
    text-align: center;
    padding: 5%;
    position: relative;
    line-height: normal;
    font-size: 20px;
    /* background-image: linear-gradient(to right,#1f2931,#1f2931);; */
    box-shadow: 1px 1px 10px 1 px black;
    transition: transform .4s;
    border-radius: 10px;
}
.countClass h5{
color: gray;
}
.countClass a {
    color: white;
    text-decoration: none;
    /* padding: 1px; */
}

.countClass a:hover {
    color: black;
    text-decoration: none;
    padding: 1px;
}
.downloadClass {
    margin-top: 10%;
    padding: 1%;
    padding-top: 5%;
    border: 2px solid black;
  }
  
  .downloadClass:hover {
    background: linear-gradient(to left, coral 50%, black 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
    color: black !important;
    /* border: 2px solid black; */
  }
  
  .downloadBtn {
    text-decoration: none;
    color: black;
  }
  
  .downloadBtn:hover {
    color: white !important;
    text-decoration: none;
  }
  



    .coder.description {
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      }
      
      .coder.description h1 {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
      }
      
      .coder.description p {
        font-size: 18px;
        color: #666666;
        margin-bottom: 20px;
      }
      
      .coder.description a {
        display: inline-block;
        background-color: #333333;
        color: #ffffff;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s ease;
      }
      
      .coder.description a:hover {
        background-color: #555555;
      }
      
      .contactBtn {
        background-color: #333333;
        color: #ffffff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .contactBtn:hover {
        background-color: coral;
        color: black !important;
      }
      
      .whatsNew {
        margin-top: 10%;
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      }
      
      .whatsNew h2 {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
      }
      
      .whatsNew p {
        font-size: 18px;
        color: #666666;
        margin-bottom: 20px;
      }
      
      .whatsNew a {
        display: inline-block;
        background-color: #333333;
        color: #ffffff;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s ease;
      }
      
      .whatsNew a:hover {
        background-color: #555555;
      }
      