.header {
  background: #111;
  height: 90px;
  padding-right: 1%;
  padding-left: 1%;
  /* position: fixed;
  width: 100%; */

}

.monoCol {
  width: auto;
  text-align: left;
  white-space: nowrap
}

.hamburgerCol {
  width: auto;
  text-align: right;
  white-space: nowrap
}

.headerMono {
  width: 150px;
  vertical-align: middle;
}

.NavigBar {
  justify-content: right;
  align-items: flex-end;
}

.headerMono:hover {
  color: #111;
  transform: scale(1.03);
  transition: .3s;
}

.navLinks {
  justify-content: flex-end;
  font-size: large;

}

.nav-link {

  position: relative;
  display: flex;

}

.nav-link a {
  text-decoration: none;
  color: white;

}

.navItems {
  align-items: center;
  padding-right: 5%;

}

.nav-link a:hover {
  color: coral;
}



.social {
  display: flex;
}

.social li {
  list-style: none;
}

.social li a {
  width: 50px;
  height: 50px;
  background-color: #fff;
  text-align: center;
  line-height: 50px;
  font-family: 'Poppins';
  font-size: 25px;
  margin: 0 7px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;



  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.8em;
}

.social li a .icon {
  position: relative;
  color: #262626;
  transition: .5s;
  z-index: 3;
}

.social li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.social li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .5s;
  z-index: 2;
}

.social li a:hover:before {
  top: 0;
}

.fb li a:before {
  background: #3b5999;
}

.twitter li a:before {
  background: #55acee;
}

.linkedin li a:before {
  background: #0077b5;
}

.insta li a:before {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}





.NavigBar .mobileNav {
  display: none;
}

@media only screen and (max-width: 850px) {
  .webNav .social {
    display: none;
  }

}

@media (max-width: 600px) {

  .headerMono {
    display: none;
  }


  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }


  .mobileTitle {
    position: relative;
    color: #fff;
    text-align: center;
    font-family: 'Dancing Script', cursive;
    z-index: 5;
    font-weight: 600;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }




  .NavigBar .webNav {
    display: none;
  }

  .NavigBar .mobileNav {
    display: flex;
  }

  .NavigBar .mobileNav .hamburgerIcon {
    position: absolute;
    right: 5%;
    top: 2.5%;
    width: 55px;
    height: 55px;
    cursor: pointer;
    color: white;
    z-index: 5;
    animation: 2s ease-out 0s 1 slideInFromRight;
  }

  .NavigBar .mobileNav .closeIcon {
    position: absolute;
    right: 5%;
    top: 2.5%;
    width: 55px;
    height: 55px;
    cursor: pointer;
    color: #262626;
    z-index: 5;

  }

  .NavigBar .navLinks {

    flex-direction: column;
    background: #181818;
    width: 100%;
    align-items: center;
    border-top: #212121 solid 1px;
    border-bottom: #363636 solid 1px;
    position: absolute;
    top: 90px;
    right: 0px;
    opacity: 1;
    z-index: 1;
    /* overflow: hidden; */

    animation: 1s ease-in 0s 1 moving;

  }

  @keyframes moving {
    from {
      top: -90px
    }
  }



  .NavigBar .navLinks .social {
    flex-direction: row;
    padding-bottom: 2%;

  }

}