.projectContainer{
    padding-top: 5%;
    padding-bottom: 5%;
    /* height: 100vh; */
    background-color: #fafafa;
    width: 100%;
    text-decoration: none;  
    position: relative;
    justify-content: center;
    align-items: center;
}

.header-center{
    height: 1px;
    background: #dddddd;
    margin-bottom: 3.2rem;
    position: relative;
}

.header-center h3{
    background: #fafafa;
    position: absolute;
    top: -0.8rem;
    left: 50%;
    margin-left: -160px;
    width: 320px;
    text-align: center;
    display: block;
    color: #757575;
    text-transform: uppercase;
    font-size: 12px;
    font-size: 1.0rem;
    font-weight: normal;
    letter-spacing: 0.2rem;
    padding: 0 !important;
    font-family: "proxima nova semibold", "Helvetica Neue", Helvetica, Arial, Sans-serif;
}
.card-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .card-body {
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
  }
  .card-body.expanded {
    overflow: visible; 
  }
  
  
  

@media (max-width: 800px)  {

    .description h1{
        font-size: x-large;
    }
    .description p{
        display: none;
    }
    
    .projectContainer{
        height: 100%;
    }}

    @media (max-width: 600px)  {
        .contentLeft .description h1{
            font-size: medium;
        }
        .projectContainer{
            padding-top: 10%;}
    }


    .appCard{
        background-color: white;
        /* height: 100%; */
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: #262626;
        overflow: hidden;
        height: 300px; /* Adjust the height as needed */
        transition: height .7s ease-in-out; /* Smooth transition for the height change */

    }
    
    .appCard:hover{
        transition: .7s;
        box-shadow: 1px 3px 10px rgb(51 51 51 / 10%);
        height: 100%;
    }

        .imgCard{
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            width: 150px;
            height: 150px;
            border-radius: 25px;
        }
       


          
          /* Prevent text overflow in the card body */
          .card-text {
         /*   display: -webkit-box;*/
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            max-height: 4.5em; /* Approx. 3 lines height, adjust if needed */
            transition: max-height .7s ease-in-out;
          }
          
          /* Expanded card text on hover */
          .appCard.hovered .card-text {
            -webkit-line-clamp: unset; /* Remove line limit */
            max-height: none; /* Allow full height */
          }
          
          